import React, { lazy } from 'react';

import { ROUTES, COPY } from '../constants';
import { Route } from '../types'

const Error404 = lazy(() => import('../pages/utils/error404.page'));
const Login = lazy(() => import('../pages/auth/login.page'));
const AcceptInvite = lazy(() => import('../pages/auth/accept-invite.page'));
const ResetPassword = lazy(() => import('../pages/auth/reset-password.page'));
const SendResetPassword = lazy(() => import('../pages/auth/send-reset-password.page'));
const Logs = lazy(() => import('../pages/logs/logs.page'));
const SubscriberManagement = lazy(() => import('../pages/subscribers/subscribers.page'));
const Unsubscribe = lazy(() => import('../pages/external/unsubscribe.page'));
const Users = lazy(() => import('../pages/users/users.page'));
const EmailConfigurations = lazy(() => import('../pages/email-configs/email-configs.page'));

export const routes: Route[] = [
  {
    path: ROUTES.LOGIN,
    element: <Login />,
    title: COPY.LOGIN.TITLE,
    protected: false,
  },
  {
    path: ROUTES.ACCEPT_INVITE,
    element: <AcceptInvite />,
    title: COPY.ACCEPT_INVITE.TITLE,
    protected: false,
  },
  {
    path: ROUTES.RESET_PASSWORD,
    element: <ResetPassword />,
    title: COPY.RESET_PASSWORD.TITLE,
    protected: false,
  },
  {
    path: ROUTES.SEND_RESET_PASSWORD,
    element: <SendResetPassword />,
    title: COPY.SEND_RESET_PASSWORD.TITLE,
    protected: false,
  },
  {
    path: ROUTES.SUBSCRIBER_MANAGEMENT,
    element: <SubscriberManagement />,
    title: COPY.SUBSCRIBER_MANAGEMENT.TITLE,
    protected: true,
  },
  {
    path: ROUTES.UNSUBSCRIBE,
    element: <Unsubscribe />,
    title: COPY.UNSUBSCRIBE.MAIN.TITLE,
    protected: false,
  },
  {
    path: ROUTES.USERS,
    element: <Users />,
    title: COPY.USERS.TITLE,
    protected: true,
  },
  {
    path: ROUTES.EMAIL_CONFIGURATIONS,
    element: <EmailConfigurations />,
    title: COPY.EMAIL_CONFIGURATIONS.TITLE,
    protected: true,
  },
  {
    path: ROUTES.LOGS,
    element: <Logs />,
    title: COPY.LOGS.TITLE,
    protected: true,
  },
  {
    path: ROUTES.ERROR404,
    element: <Error404 />,
    title: COPY.ERROR404.TITLE,
    protected: false,
  },
];

export default routes;
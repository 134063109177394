export const COPY = {
    ERROR404: {
        TITLE: "Error 404",
        DESCRIPTION: "The requested resource could not be found.",
    },
    LOGIN: {
        TITLE: 'Welcome'
    },
    ACCEPT_INVITE: {
        TITLE: "Account Creation"
    },
    RESET_PASSWORD: {
        TITLE: "Reset Password",
        SUCCESS: {
            TITLE: 'Success!',
            DESCRIPTION: 'Your password has been reset.'
        }
    },
    SEND_RESET_PASSWORD: {
        TITLE: "Reset Password"
    },
    UNSUBSCRIBE: {
        MAIN: {
            TITLE: "Unsubscribe from Emails",
            DESCRIPTION: "Are you sure you would like to unsubscribe from the email campaign?",
        },
        UNSUBSCRIBED: {
            TITLE: "Email preferences updated",
            DESCRIPTION: "You have been successfully unsubscribed. You may now close this window.",
        },
        CANCEL: {
            TITLE: "Request cancelled",
            DESCRIPTION: "Your email preferences have not been changed. You may now close this window or return to your email settings.",
        },
    },
    LOGS: {
        TITLE: 'Logs',
        TABLES: {
            SUBSCRIBER_ACTIVITY: {
                KEY: 'Subscriber Activity',
                HEADERS: ['Subscriber ID', 'Action', 'Date'],
                COLUMNS: ['subscriber_id', 'activity', 'created_at']
            },
            EMAIL_ACTIVITY: {
                KEY: 'Email Activity',
                HEADERS: ['Subscriber ID', 'Email ID', 'Day', 'Date'],
                COLUMNS: ['subscriber_id', 'email_config_id', 'day', 'created_at']
            },
        }
    },
    SUBSCRIBER_MANAGEMENT: {
        TITLE: 'Subscriber Management',
        TABLES: {
            SUBSCRIBER_MANAGEMENT: {
                KEY: 'All Subscribers',
                HEADERS: ['ID', 'Day', 'Email', 'Status'],
                COLUMNS: ['id', 'day', 'email', 'status'],
                BUTTON_LABEL: 'Add Subscriber'
            }
        },
        MODULES: {
            ADD_SUBSCRIBER: {
                TITLE: 'Add Subscriber',
                DESCRIPTION: 'Add a new subscriber to the email campaign.'
            },
            EDIT_SUBSCRIBER: {
                TITLE: 'Edit Subscriber',
                DESCRIPTION: "Edit a subscriber's information in the email campaign."
            },
            TOGGLE_SUBSCRIBER: {
                TITLE: 'Toggle Subscriber Settings',
                DESCRIPTION: "Modify a subscriber's subscription settings. Caution: Actions are destructive."
            }
        }
    },
    EMAIL_CONFIGURATIONS: {
        TITLE: 'Email Configurations',
        TABLES: {
            EMAIL_CONFIGURATIONS: {
                KEY: 'All Configurations',
                HEADERS: ['ID', 'Day', 'Image', 'Alt Text', 'Header', 'Footer', 'Last Updated'],
                COLUMNS: ['id', 'day', 'asset_url', 'alternative_text', 'header', 'footer', 'updated_at'],
                BUTTON_LABEL: 'Add Email to Campaign'
            }
        },
        MODULES: {
            ADD_EMAIL: {
                TITLE: 'Add New Email',
                DESCRIPTION: 'Add a new Email to the campaign. Emails can be added from day 1 onwards through to the last day but not beyond. By adding emails to the middle of the queue, subsequent emails will be pushed by one day.'
            },
            EDIT_EMAIL: {
                TITLE: 'Edit Email',
                DESCRIPTION: "Edit an existing email's text and/or image information."
            },
            TOGGLE_EMAIL: {
                TITLE: 'Delete Email',
                DESCRIPTION: "Delete an existing email configuration."
            }
        }
    },
    USERS: {
        TITLE: 'User Management',
        TABLES: {
            USER_MANAGEMENT: {
                KEY: 'All Users',
                HEADERS: ['ID', 'Name', 'Email', 'Status'],
                COLUMNS: ['id', 'full_name', 'email', 'activity'],
                BUTTON_LABEL: 'Add User'
            }
        },
        MODULES: {
            ADD_USER: {
                TITLE: 'Add User',
                DESCRIPTION: 'Add a new user to the portal manager.'
            },
            EDIT_USER: {
                TITLE: 'Edit User',
                DESCRIPTION: "Edit a user's information in the portal manager."
            },
            TOGGLE_USER: {
                TITLE: 'Modify User Access',
                DESCRIPTION: "Modify a user's access to the platform."
            }
        }
    },
};

export default COPY;
import axios from '../config/axios';
import { API } from '../config/api';
import { User, LoginParams, LoginResponse } from '../types'
import formatEndpoint from '../utils/api/api';

export async function loginUser(params: LoginParams): Promise<LoginResponse> {
  return axios.post(API.auth.login, params);
};

export async function getCurrentUser(): Promise<User> {
  return axios.get(API.users.current_user);
};

export function logoutUser() {
  return axios.post(API.auth.logout);
};

export async function sendPasswordReset(email: string) {
  return axios.post(API.auth.reset_password, { email: email });
}

export const getReset = async (id: string, token: string) => {
  const endpoint = formatEndpoint(API.auth.password_by_id, { id: id, token: token });
  return axios.get(endpoint);
};

export const resetPassword = (id: string, token: string, password: string) => {
  const endpoint = formatEndpoint(API.auth.password_by_id, { id: id, token: token });
  return axios.post(endpoint, { password: password });
};

export const inviteUser = async (email: string, fullName: string) => {
  const data = { email: email, full_name: fullName };
  const endpoint = API.auth.invite_user;
  return axios.post(endpoint, data, { headers: { 'Content-Type': 'application/json' } });
};

export const getInvite = (id: string, token: string,): Promise<{ email: string; full_name: string }> => {
  const endpoint = formatEndpoint(API.auth.accept_invite, { id: id, token: token });
  return axios.get(endpoint);
};

export const acceptInvite = (id: string, token: string, password: string): Promise<User> => {
  const endpoint = formatEndpoint(API.auth.accept_invite, { id: id, token: token });
  return axios.post(endpoint, { password });
};
// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.spinner-container {
    margin-top: 75px;
    margin-bottom: 75px;
    margin-left: 100px;
    margin-right: 100px;
}

.page-spinner-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.3);
    z-index: 4;
    display: flex;
    align-items: center;
    justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/themes/components/spinner/spinner.styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,oCAAoC;IACpC,UAAU;IACV,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B","sourcesContent":[".spinner-container {\n    margin-top: 75px;\n    margin-bottom: 75px;\n    margin-left: 100px;\n    margin-right: 100px;\n}\n\n.page-spinner-container {\n    position: fixed;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background: rgba(255, 255, 255, 0.3);\n    z-index: 4;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;

import React, { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';

import { getCurrentUser, loginUser, logoutUser } from '../services/auth.service';
import { ROUTES } from '../constants';
import { AuthContextType, User } from '../types';
import routes from '../config/router';

const AuthContext = createContext<AuthContextType>({} as AuthContextType);

export function AuthProvider({ children }: Readonly<{ children: ReactNode }>): JSX.Element {
  const [user, setUser] = useState<User>();
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingInitial, setLoadingInitial] = useState<boolean>(true);

  const navigate = useNavigate();
  const location = useLocation();

  const getUser = async () => {
    try {
      const currentUser = await getCurrentUser();
      setUser(currentUser);
    } catch (err) {
      setError(err);
      setUser(undefined);
    } finally {
      setLoadingInitial(false);
    }
  };

  useEffect(() => {
    if (error) setError(null);
  }, [location, error]);

  useEffect(() => {
    const currentRoute = routes.find(route => route.path === location.pathname);
    if (currentRoute?.protected || currentRoute?.path === ROUTES.LOGIN) {
      getUser();
    }
    else {
      setUser(undefined);
      setLoadingInitial(false);
    }
  }, [location]);

  const login = async (email: string, password: string) => {
    try {
      setLoading(true);
      await loginUser({ email, password });
      await getUser();
      toast.success('Login successful');
    } catch (err) {
      toast.error(`Unable to login`);
      setError(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const logout = async () => {
    try {
      setLoading(true);
      logoutUser();
      setUser(undefined);
      navigate(ROUTES.LOGIN);
      toast.success('Logout successful');
    } catch (err) {
      setError(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const timeout = async () => {
    try {
      setLoading(true);
      logoutUser();
      setUser(undefined);
      navigate(ROUTES.LOGIN);
      toast.error('Your session has expired, please login again');
    } catch (err) {
      setError(err);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const memoedValue = useMemo(
    () => ({
      user,
      getUser,
      loading,
      error,
      login,
      logout,
      timeout,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user, loading, error],
  );

  return (
    <AuthContext.Provider value={memoedValue}>{!loadingInitial && children}</AuthContext.Provider>
  );
}

export default function useAuth() {
  return useContext(AuthContext);
}
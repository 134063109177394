import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider } from '@mui/material/styles';

import { defaultTheme } from '../../themes/components/spinner/spinner.theme';
import CenterBox from '../layout/center-box';
import '../../themes/components/spinner/spinner.styles.css';

export const Spinner = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
        <CircularProgress className='spinner' />
    </ThemeProvider>
  );
};

export const PageSpinner = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <CenterBox className='invisible'>
        <CircularProgress className='spinner' />
      </CenterBox>
    </ThemeProvider>
  );
};

export const ContainerSpinner = () => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <div className='spinner-container'>
        <CircularProgress className='spinner' />
      </div>
    </ThemeProvider>
  );
};
export const ROUTES = {
    LOGIN: '/',
    LOGOUT: '/logout',
    ACCEPT_INVITE: '/accept-invite/:id/:token',
    SEND_RESET_PASSWORD: '/send-reset-password',
    RESET_PASSWORD: '/reset-password/:id/:token',
    SUBSCRIBER_MANAGEMENT: '/subscriber/management',
    UNSUBSCRIBE: '/unsubscribe/:key',
    EMAIL_CONFIGURATIONS: '/email/configurations',
    LOGS: '/logs',
    USERS: '/users',
    ERROR404:'*',
};

export default ROUTES;
export const LOCATION = {
    CITIES:[
    "Eastern Urban",
    "Eastern Rural",
    "Central",
    "Western",
    "Labrador"

]};

export default LOCATION;
export const API_ROOT = `${process.env.REACT_APP_BACKEND_URL}/api`

export const API = {
  auth: { //User authentication management
    login: `${API_ROOT}/auth/login`,
    logout: `${API_ROOT}/auth/logout`,
    invite_user: `${API_ROOT}/auth/invite`,
    accept_invite: `${API_ROOT}/auth/invite/{id}/{token}`,
    reset_password: `${API_ROOT}/auth/reset-password`,
    password_by_id: `${API_ROOT}/auth/reset-password/{id}/{token}`,
  },
  external: {
    default: `${API_ROOT}/external`,
    email_test:`${API_ROOT}/external/send`
  },
  users: { //Users management
    default: `${API_ROOT}/users`, //get
    current_user: `${API_ROOT}/users/current`, //get current user
    by_id: `${API_ROOT}/users/{id}`, //delete+edit
    deactivate: `${API_ROOT}/users/deactivate/{id}`, //deactivate
    activate: `${API_ROOT}/users/activate/{id}`, //activate
  },
  subscribers: {
    default: `${API_ROOT}/subscribers`, //create + get(paginated)
    by_id: `${API_ROOT}/subscribers/{id}`, //delete, edit
    unsubscribe: `${API_ROOT}/subscribers/unsubscribe/{id}`,
  },
  email_config: {
    default: `${API_ROOT}/email-configs`,
    by_id: `${API_ROOT}/email-configs/{id}`
  },
  logs: {
    email_default: `${API_ROOT}/logs/email`,
    subscriber_default: `${API_ROOT}/logs/subscribers`,
  },
};
import React, { Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';

import useAuth from '../hooks/useAuth';
import routes from '../config/router';
import  { Route as RouteType }  from '../types';
import { ROUTES } from '../constants';

import { PageSpinner } from '../components/spinner/spinner'

const AppRoutes = () => {
  const { user } = useAuth();

  const generateRouteElement = (element: React.ReactNode) => (
      <>{element}</>
  );

  const hasToken = () => {
    if (user) {
      return true;
    }
    return false;
  };

  const returnRoutes = (projectRoutes: RouteType[]) => {
    return projectRoutes.map((route: RouteType) => {
      const { path, element, protected: isProtected } = route;

      if (isProtected && !hasToken()) {
        return (
          <Route
            key={path}
            path={path}
            element={<Navigate key={path} to={ROUTES.LOGIN} />}
          />
        );
      }
      return <Route key={path} path={path} element={generateRouteElement(element)} />;
    });
  };

  return (
    <Suspense fallback={<PageSpinner />}>
      <Routes>
        {returnRoutes(routes)}
      </Routes>
    </Suspense>
  );
};

export default AppRoutes;
import axios, { AxiosError } from 'axios';

axios.interceptors.request.use(
  (config) => {
    config.withCredentials = true;
    return config;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error: AxiosError) => {
    return Promise.reject(error);
  },
);

export default axios;
export const formatEndpoint = (
    endpoint: string,
    data: Record<string, string | number>,
  ) => {
    let updatedEndpoint = endpoint;
    Object.entries(data).forEach((entry) => {
      const [key, value] = entry;
      updatedEndpoint = updatedEndpoint.replaceAll(`{${key}}`, value as string);
    });
  
    if (updatedEndpoint.indexOf('{') > -1) {
      throw new Error(
        `You seem to be missing an expected property on this endpoint. Ensure you have the follow: ${Object.keys(
          data,
        )?.join(', ')}`,
      );
    }
  
    return updatedEndpoint;
  };
  
  export default formatEndpoint;
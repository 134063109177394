import React, { Fragment } from 'react';

import Box from '@mui/material/Box';

import { CenteredBoxProps } from '../../types';

import '../../themes/components/layout/center-box.styles.css';

const CenterBox: React.FC<CenteredBoxProps> = ({ children, className }) => {
  const boxClassName = `centered-box ${className}`;

  return (
    <Fragment>
      <Box className={boxClassName}>
        <div className='center-box-container'>
          {children}
        </div>
      </Box>
      {className === 'visible' && (
        <div className='blur-backdrop'></div>
      )}
    </Fragment>
  );
};

export default CenterBox;